import React from "react";
import Table from "react-bootstrap/Table";
import * as XLSX from "xlsx";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { currencyDisplay } from "../../utils/cart/cart";
import "./CajaDiaria.Module.css";
import { formatDate } from "../../utils/validate/validate";
import { useMediaQuery } from 'react-responsive';
import axios from "axios";


const DetalleCaja = ({ cajaData }) => {
	const isMobile = useMediaQuery({ maxWidth: 700 });
	const isMobileSm = useMediaQuery({ maxWidth: 400 });
	const token = localStorage.getItem("Token");
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	const [detalleVentasState, setDetalleVentasState] = React.useState(cajaData.detalleVentas);
	const [saldoFinalChecked, setSaldoFinalChecked] = React.useState(cajaData.saldoFinal.checked);
	const isMobileScreen = useMediaQuery({ maxWidth: 768 });
	const [ingresosChecked, setIngresosChecked] = React.useState(
		cajaData.ingresos?.map((ingreso) => ingreso.checked)
	);
	const [egresosChecked, setEgresosChecked] = React.useState(
		cajaData.egresos?.map((egreso) => egreso.checked)
	);

	const handleCheckboxChange = (medioPago) => {
		// Copiar el estado actual
		const updatedDetalleVentas = { ...detalleVentasState };

		// Cambiar el estado "checked" del medio de pago
		updatedDetalleVentas[medioPago].checked = !updatedDetalleVentas[medioPago].checked;

		// Actualizar el estado local
		setDetalleVentasState(updatedDetalleVentas);
	};

	const handleCheckboxChangeSaldoFinal = () => {
		// Cambiar el estado "checked" de saldoFinal
		setSaldoFinalChecked(!saldoFinalChecked);
	};
	const handleCheckboxChangeIngreso = (index) => {
		const updatedIngresosChecked = [...ingresosChecked];
		updatedIngresosChecked[index] = !updatedIngresosChecked[index];
		setIngresosChecked(updatedIngresosChecked);
	};

	const handleCheckboxChangeEgreso = (index) => {
		const updatedEgresosChecked = [...egresosChecked];
		updatedEgresosChecked[index] = !updatedEgresosChecked[index];
		setEgresosChecked(updatedEgresosChecked);
	};
	const [ordenAlfabetico, setOrdenAlfabetico] = React.useState(false);
	const [medioPagoSeleccionado, setMedioPagoSeleccionado] = React.useState("");

	const comprasFiltradas = cajaData.comprasDelDia
  .filter((compra) => {
    if (!medioPagoSeleccionado) {
      return true; // No se ha seleccionado ningún medio, mostrar todas las compras
    } else if (medioPagoSeleccionado === "restablecer") {
      return true; // Mostrar todas las compras en caso de "Restablecer"
    } else {
      return compra.mediosDePago.some(
        (medioPago) => medioPago.medioPago === medioPagoSeleccionado
      );
    }
  })
  .sort((a, b) => {
    if (ordenAlfabetico) {
      // Ordenar alfabéticamente por el nombre del cliente
      const nombreA = a.cliente.toUpperCase();
      const nombreB = b.cliente.toUpperCase();

      if (nombreA < nombreB) {
        return -1;
      }
      if (nombreA > nombreB) {
        return 1;
      }
      return 0;
    } else {
      return 0; // Sin orden alfabético, no se realiza ningún cambio en el orden
    }
  });




	

	if (!cajaData) {
		return null;
	}
	const fechaFormateada = formatDate(cajaData.fecha);

	const exportToExcel = () => {
		const workbook = XLSX.utils.book_new();

		// Datos de ingresos
		const ingresosData = cajaData?.ingresos?.map((ingreso) => ({
			"Motivo Ingreso": ingreso.motivo,
			"Monto Ingreso": ingreso.monto,
		}));

		// Datos de egresos
		const egresosData = cajaData?.egresos?.map((egreso) => ({
			"Motivo Egreso": egreso.motivo,
			"Monto Egreso": egreso.monto,
		}));

		// Detalle de ventas
		const ventasData = Object.keys(detalleVentasState).map((venta) => ({
			"Medio de Pago": venta,
			"Monto Medio de Pago": detalleVentasState[venta]?.monto,
		}));

		// Datos de compras del día (nueva tabla)
		const comprasDiaData = [];
		if (cajaData.comprasDelDia) {
			cajaData.comprasDelDia.forEach((compra, compraIndex) => {
				compra.mediosDePago.forEach((medioPago, medioPagoIndex) => {
					const rowData = {
						Compras: medioPagoIndex === 0 ? compraIndex + 1 : "", // Agrega el número de compra solo en la primera fila
						Cliente: medioPagoIndex === 0 ? compra.cliente : "", // Agrega el nombre del cliente solo en la primera fila
						"Medio de Pago": medioPago.medioPago,
						"Monto Medio de Pago": medioPago.monto,
					};
					comprasDiaData.push(rowData);
				});
			});
		}

		//Combina todos los datos
		const data = [
			{
				Fecha: fechaFormateada,
				Estado: cajaData.estado,
				"Saldo Inicial": cajaData.saldoInicial,
				"Saldo Final": cajaData.saldoFinal.monto,
			},
			...ingresosData,
			...egresosData,
			...ventasData,
		];

		// Ajusta las filas de manera que todos los datos comiencen desde la fila 2
		const adjustedData = [];
		for (let i = 0; i < Math.max(ingresosData.length, ventasData.length); i++) {
			adjustedData.push({
				Fecha: i === 0 ? fechaFormateada : "",
				Estado: i === 0 ? cajaData.estado : "",
				"Saldo Inicial": i === 0 ? cajaData.saldoInicial : "",
				...ingresosData[i],
				...egresosData[i],
				...ventasData[i],
				"Saldo Final": i === 0 ? cajaData.saldoFinal.monto : "",
			});
		}

		// Hoja para los datos de compras del día
		const wsComprasDia = XLSX.utils.json_to_sheet(comprasDiaData);
		XLSX.utils.book_append_sheet(workbook, wsComprasDia, "Compras del Día"); // Agrega una nueva hoja para compras del día

		// Hoja para los datos combinados
		const ws = XLSX.utils.json_to_sheet(adjustedData);
		XLSX.utils.book_append_sheet(workbook, ws, `DetalleCaja_${fechaFormateada}`); // Agrega la hoja existente

		XLSX.writeFile(workbook, `detalle_caja_${fechaFormateada}.xlsx`);
	};

	// Función para enviar una solicitud PUT al backend y actualizar los checks
	const actualizarChecks = async () => {
		try {
			const idCaja = cajaData?.id ? cajaData?.id : cajaData?._id
			const response = await axios.put(
				`${process.env.REACT_APP_BACK_URL}/api/cajadiaria/actualizarchecks/${idCaja}`,
				{
					detalleVentas: detalleVentasState,
					ingresosChecked,
					egresosChecked,
					saldoFinalChecked,
				},
				config
			);

			if (response.status === 200) {
				// Los checks se actualizaron con éxito, puedes mostrar una notificación o realizar cualquier otra acción deseada.
				toast.success("Los checks se actualizaron con éxito");
			} else {
				// Manejar errores en la respuesta
				toast.error("Error al actualizar los checks");
			}
		} catch (error) {
			toast.error("Error al actualizar los checks", error);
		}
	};

	const getSaldoFinalSummaryText = () => {
		if (cajaData.saldoFinal && cajaData.saldoInicial && cajaData.detalleVentas) {
			const saldoInicial = cajaData.saldoInicial;
			const ventasEfvo = cajaData.detalleVentas["VENTAS EFVO"]?.monto || cajaData.detalleVentas["EFECTIVO"]?.monto ||0;
			const ingresosTotal = cajaData.ingresos?.reduce((total, ingreso) => total + ingreso.monto, 0)|| 0;
			const egresosTotal = cajaData.egresos?.reduce((total, egreso) => total + egreso.monto, 0)|| 0;
			const saldoFinal = saldoInicial + ventasEfvo + ingresosTotal - egresosTotal;

			return `Saldo Inicial: ${currencyDisplay(saldoInicial)}\n+ Ventas Efectivo: ${currencyDisplay(
				ventasEfvo
			)}\n+ Ingresos: ${currencyDisplay(ingresosTotal)}\n- Egresos: ${currencyDisplay(
				egresosTotal
			)}\n= Saldo Final: ${currencyDisplay(saldoFinal)}`;
		}
		return "";
	};

	return (
		<div style={{transform:isMobile?"scale(0.9)":isMobileSm?"scale(0.7)":""}}>
			<h2>Detalle de la Caja</h2>
			<Table responsive="sm" striped bordered hover>
				<thead>
					<tr>
						<th>Fecha</th>
						<th>Estado</th>
						<th>Saldo Inicial</th>
						<th>{cajaData?.estado ==="ABIERTA"?"Saldo parcial":"Saldo final caja (S.I. + Ventas EFVO)"}</th>
						
							<th>Check</th>

					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{fechaFormateada}</td>
						<td className={cajaData.estado === "ABIERTA" ? "abierta" : "cerrada"}>
							{cajaData.estado}
						</td>
						<td>{currencyDisplay(cajaData?.saldoInicial)}</td>
						<td className="saldoFinal">
							<abbr className="montoSaldo" title={getSaldoFinalSummaryText()}>
								{currencyDisplay(cajaData?.saldoFinal?.monto ? cajaData.saldoFinal.monto : 0)}
							</abbr>
						</td>
						
						<td className={saldoFinalChecked ? "checked" : ""}>
							<input
								type="checkbox"
								checked={saldoFinalChecked}
								onChange={handleCheckboxChangeSaldoFinal}
							/>
						</td>
						
					</tr>
				</tbody>
			</Table>
			{cajaData?.detalleVentas && (
				<>
					<h3>Detalle de medios al cierre</h3>
					
					<Table responsive="sm" striped bordered hover >
						<thead>
							<tr>
								<th>Tipo de Venta</th>
								<th>Monto</th>								
								<th>Check</th> 						
							</tr>
						</thead>
						<tbody>
							{cajaData?.detalleVentas &&
								Object.entries(detalleVentasState).map(([tipo, data], index) => (
									<tr key={index}>
										<td style={{backgroundColor:data.color || ""}}>{tipo}</td>
										<td>{currencyDisplay(data.monto)}</td>
										
										<td className={data.checked ? "checked" : ""}>
											<input
												type="checkbox"
												checked={data.checked}
												onChange={() => handleCheckboxChange(tipo)}
											/>
										</td>
										
									</tr>
								))}
						</tbody>
					</Table>
				</>
			)}
			{/* {cajaData.comprasDelDia && ( */}

				{comprasFiltradas?.length > 0 && (
				<>
					<h3>Detalle de ventas del día</h3>
					<Table responsive="sm"striped bordered hover >
					<thead>
    <tr>
      <th style={{ textAlign: 'center' }}>
        Cliente
        <div style={{ textAlign: 'center' }}>
          <label style={{ fontSize: '10px' }}>
            {`Ordenar(a-z)`}
            <input
              style={{ width: '25px' }}
              type="checkbox"
              checked={ordenAlfabetico}
              onChange={() => setOrdenAlfabetico(!ordenAlfabetico)}
            />
          </label>
        </div>
      </th>
      <th style={{ textAlign: 'center' }}>
        Medios de pago
        <select
          style={{ width: '100%', fontSize:"12px" }}
          value={medioPagoSeleccionado}
          onChange={(e) => setMedioPagoSeleccionado(e.target.value)}
        >
          <option value="">Todos</option>
          {Object.keys(detalleVentasState).map((tipo, index) => (
            <option key={index} value={tipo}>
              {tipo}
            </option>
          ))}
        </select>
      </th>
      <th style={{ textAlign: 'center' }}>
        Detalle
      </th>
			<th>
				Atendido por
			</th>
			<th>
				Notas
			</th>
    </tr>
  </thead>
						<tbody>
							{/* {cajaData?.comprasDelDia?.map((compra, index) => ( */}
							{comprasFiltradas.map((compra, index) => (
								<tr key={index}>
									<td>{compra?.cliente?.toUpperCase()}</td>
									<td>
										<ul>
											{compra?.mediosDePago.map((medioPago, subIndex) => (
												<li style={{ fontSize:isMobileScreen ?"12px":"14px"}} key={subIndex}>
													{medioPago.medioPago} - {currencyDisplay(medioPago.monto)}
												</li>
											))}
										</ul>
									</td>
									<td>
										<ul>
											{compra?.detalle?.map((producto, subIndex) => (
												<li  style={{ fontSize:isMobileScreen ?"12px":"14px"}} key={subIndex}>
													{producto.producto} - ({producto?.cantidad})
												</li>
											))}
										</ul>
									</td>
									<td>
										<span>{compra?.atendidoPor?.nombre} {compra?.atendidoPor?.apellido}</span>
									</td>
									<td>
										<span>{compra?.notas}</span>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</>
			)}

			{cajaData?.egresos && cajaData?.egresos?.length > 0  && (
				<>
					<h3>Egresos extraordinarios</h3>
					<Table striped bordered hover>
						<thead>
							<tr>
								<th>Motivo del egreso</th>
								<th>Monto</th>
								
								<th>Check</th>
								
							</tr>
						</thead>
						<tbody>
							{cajaData.egresos?.map((egreso, index) => (
								<tr key={index}>
									<td>{egreso.motivo}</td>
									<td>{currencyDisplay(egreso.monto)}</td>
									
									<td className={egresosChecked[index] ? "checked" : ""}>
										<input
											type="checkbox"
											checked={egresosChecked[index]}
											onChange={() => handleCheckboxChangeEgreso(index)}
										/>
									</td>
									
								</tr>
							))}
						</tbody>
					</Table>
				</>
			)}
			{cajaData.ingresos && cajaData.ingresos.length > 0 && (
				
				<>
			

					<h3>Ingresos extraordinarios</h3>
					<Table striped bordered hover>
						<thead>
							<tr>
								<th>Motivo del ingreso</th>
								<th>Monto</th>
							
								<th>Check</th>
								
							</tr>
						</thead>
						<tbody>
							{cajaData.ingresos?.map((ingreso, index) => (
								<tr key={index}>
									<td>{ingreso.motivo}</td>
									<td>{currencyDisplay(ingreso.monto)}</td>
								
									<td className={ingresosChecked[index] ? "checked" : ""}>
										<input
											type="checkbox"
											checked={ingresosChecked[index]}
											onChange={() => handleCheckboxChangeIngreso(index)}
										/>
									</td>
									
								</tr>
							))}
						</tbody>
					</Table>
				</>
			)}

			{cajaData?.notas && <p>Notas: {cajaData.notas}</p>}
			<Button variant="success" onClick={() => exportToExcel(cajaData)}>
				Exportar a Excel
			</Button>
			
			<Button variant="primary" onClick={actualizarChecks}>
				Actualizar Checks
			</Button>
			
		</div>
	);
};

export default DetalleCaja;
